const { GET_INGREDIENTS, GET_INGREDIENTS_ERROR } = require('../actions/types');

const initialState = {
  data: null,
  loading: true,
  error: null,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_INGREDIENTS:
      return {
        ...state,
        data: payload,
        loading: false,
      };

    case GET_INGREDIENTS_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    default:
      return state;
  }
}
