import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import { LOGOUT } from './actions/types';
import store from './store';

import Routes from './components/routing/Routes';
import AppTopBar from './components/TopBar';
import HomePage from './pages/HomePage';
import { loadUser } from './actions/auth';

// Redux
import { Provider } from 'react-redux';

const App = () => {
  useEffect(() => {
    // check for token in LS
    store.dispatch(loadUser());
    // log user out from all tabs if they log out in one tab
    window.addEventListener('storage', () => {
      if (!localStorage.token) store.dispatch({ type: LOGOUT });
    });
  }, []);
  return (
    <Provider store={store}>
      <Router>
        <AppTopBar>
          <Switch>
            <Route exact path='/' component={HomePage} />
            <Route component={Routes} />
          </Switch>
        </AppTopBar>
      </Router>
    </Provider>
  );
};

export default App;
