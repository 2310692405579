import { BOOK_ORDER_API, BOOK_ORDER_API_ERROR } from './../actions/types';



const initialState = {
  fulfillmentData: null,
  loading: true,
  error: null,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case BOOK_ORDER_API:
      return {
        ...state,
        fulfillmentData: payload,
        loading: false,
      };
    case BOOK_ORDER_API_ERROR:
      return {
        ...state,
        error: 'Unable to Fulfill Order',
        loading: false,
      };
    default:
      return state;
  }
}
