import axios from 'axios';
import useAxios from '../utils/axiosInstance';
import { setAlert } from './alert';
import { setAuthToken } from '../utils/setAuthToken';

import {
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  USER_LOADED,
  OPT_CHECK,
  AUTH_ERROR,
  PROFILE_UPDATE,
  PROFILE_UPDATE_ERROR,
  LOGOUT,
  // CLEAR_PROFILE,
} from './types';

const config = {
  headers: {
    'Content-Type': 'application/json',
  },
};

export const loadUser = () => async (dispatch) => {
  try {
    const res = await (await useAxios()).get('/api/me');
    dispatch({
      type: USER_LOADED,
      payload: res.data,
    });
  } catch (err) {
    setAuthToken();
    dispatch({
      type: AUTH_ERROR,
      payload: 'error', //{ msg: err.response.statusText },
    });
  }
};

export const login = (email, password) => async (dispatch) => {
  const loginData = {
    email,
    password,
  };
  const body = JSON.stringify(loginData);
  try {
    const res = await axios.post('/api/login', body, config);

    if (res.data.auth) {
      dispatch({
        type: OPT_CHECK,
        payload: res.data.twoFactorToken,
      });
    } else {
      dispatch({
        type: LOGIN_SUCCESS,
        payload: res.data,
      });
      dispatch(loadUser());
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'critical')));
    }
    dispatch({
      type: LOGIN_FAIL,
      payload: 'error',
    });
  }
};

export const verifyOPT = (formData) => async (dispatch) => {
  try {
    const body = JSON.stringify(formData);
    const res = await axios.post('/api/authenticate', body, config);

    dispatch({
      type: LOGIN_SUCCESS,
      payload: res.data,
    });
    dispatch(loadUser());
  } catch (error) {
    const errors = error.response.data.errors;
    if (errors) {
      errors.forEach((error) =>
        dispatch(setAlert(error.msg, 'critical', 'opt'))
      );
    }
  }
};

export const updateUserProfile = (formData, userId) => async (dispatch) => {
  try {
    const staffData = await (
      await useAxios()
    ).patch(`/api/profile-update/${userId}`, formData, config);
    dispatch(setAlert('User Updated', 'userUpdate'));
    dispatch({
      type: PROFILE_UPDATE,
      payload: staffData.data,
    });
  } catch (error) {
    const errors = error.response.data.errors;

    if (errors) {
      errors.forEach((error) =>
        dispatch(setAlert(error.msg, 'userUpdateError'))
      );
    }
    dispatch({
      type: PROFILE_UPDATE_ERROR,
      payload: { msg: error.response.status },
    });
  }
};

export const logout = () => async (dispatch) => {
  try {
    const res = await (await useAxios()).get('/api/logout');
    dispatch({ type: LOGOUT });
    dispatch(setAlert(res.data.msg, 'success'));
  } catch (error) {
    const errors = error.response.data.errors;
    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'critical')));
    }
    dispatch({ type: LOGOUT });
  }
};
