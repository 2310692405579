import {
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  USER_LOADED,
  AUTH_ERROR,
  OPT_CHECK,
  PROFILE_UPDATE,
  PROFILE_UPDATE_ERROR,
} from '../actions/types';

const initialState = {
  token: localStorage.getItem('token'),
  isAuthenticated: null,
  optToken: '',
  loading: true,
  user: null,
  error: null,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case USER_LOADED:
    case PROFILE_UPDATE:
      return {
        ...state,
        user: payload,
        isAuthenticated: true,
        loading: false,
      };
    case LOGIN_SUCCESS:
      localStorage.setItem('token', JSON.stringify(payload));
      return {
        ...state,
        ...payload,
        isAuthenticated: true,
        optToken: '',
        loading: false,
      };
    case OPT_CHECK:
      return {
        ...state,
        loading: false,
        optToken: payload,
      };
    case LOGIN_FAIL:
    case LOGOUT:
    case AUTH_ERROR:
      localStorage.removeItem('token');
      return {
        ...state,
        token: null,
        isAuthenticated: false,
        loading: false,
        user: null,
      };
    case PROFILE_UPDATE_ERROR:
      return {
        ...state,
        ...payload,
        isAuthenticated: true,
        loading: false,
        error: payload,
      };
    default:
      return state;
  }
}
