const { GET_SMS_RECORD } = require('../actions/types');

const initialState = {
  data: null,
  loading: true,
  error: null,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_SMS_RECORD:
      return {
        ...state,
        data: payload,
        loading: false,
        error: null,
      };
    default:
      return state;
  }
}
