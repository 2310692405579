import React, { Suspense, lazy } from "react";
import { Route, Switch } from "react-router-dom";

const Login = lazy(() => import("../auth/Login"));
const Dashboard = lazy(() => import("../dashboard/Dashboard"));
const Orders = lazy(() => import("../dashboard/Orders"));
const OrdersAnalytics = lazy(() => import("../dashboard/OrdersAnalytics"));
const BookedPackets = lazy(() => import("../dashboard/BookedPacket"));
const BookedOrder = lazy(() => import("../dashboard/BookOrder"));
const MergeOrders = lazy(() => import("../dashboard/MergeOrders"));
const BookOrderTcs = lazy(() => import("../dashboard/BookOrderTcs"));
const BookOrderMnP = lazy(() => import("../dashboard/BookOrderMnP"));
const Staff = lazy(() => import("../dashboard/Staff"));
const UserById = lazy(() => import("../dashboard/UserById"));
const UserStats = lazy(() => import("../dashboard/UserStats"));
const StaffStats = lazy(() => import("../dashboard/StaffStats"));

const NewUser = lazy(() => import("../dashboard/NewUser"));
const UserProfile = lazy(() => import("../dashboard/UserProfile"));
const Logout = lazy(() => import("../dashboard/Logout"));
const TrackOrderByName = lazy(() => import("../dashboard/TrackOrderByName"));
const AbandonedCard = lazy(() => import("../dashboard/AbandonedCart"));
const IngredientsRecord = lazy(() => import("../dashboard/IngredientsRecord"));

// const  TrackOrder = lazy(() => import()) '../TrackOrder';
// const  TrackOrderTcs = lazy(() => import()) '../TrackOrderTcs';
const OrderByProducts = lazy(() => import("../dashboard/OrderByProducts"));
const RedirectPage = lazy(() => import("../RedirectPage"));
const VerifyOrder = lazy(() => import("../VerifyOrder"));
const SettingsSMS = lazy(() => import("../dashboard/SettingsSMS"));
const SMSStatus = lazy(() => import("../dashboard/SMSStatus"));
const CallSettings = lazy(() => import("../dashboard/CallSettings"));
const SurveySettings = lazy(() => import("../dashboard/SurveySettings"));
const Templates = lazy(() => import("../dashboard/Templates"));
const NewTemplates = lazy(() => import("../dashboard/NewTemplate"));
const TemplateById = lazy(() => import("../dashboard/TemplateById"));
const SendTemplateMessage = lazy(() =>
  import("../dashboard/SendTemplateMessage")
);
const TemplateRecords = lazy(() => import("../dashboard/TempleteRecords"));
const ReturnShipments = lazy(() => import("../dashboard/ReturnShipments"));
const ReturnShipmentsRecord = lazy(() =>
  import("../dashboard/ReturnShipmentsRecord")
);
const SendMessage = lazy(() => import("../dashboard/SendMessage"));
const SMSRecord = lazy(() => import("../dashboard/SMSRecord"));

const Translations = lazy(() => import("../dashboard/ProductTranslation"));
const Translation = lazy(() => import("../dashboard/Translaton"));

const ShortURLs = lazy(() => import("../dashboard/shorturls/ShortURLs"));
const CreateShortURLs = lazy(() => import("../dashboard/shorturls/Create"));
const ShortURLSettings = lazy(() => import("../dashboard/shorturls/Settings"));

// Leopard API
const Leopard = lazy(() => import("../dashboard/Leopard/Leopard"));
const LeopardCreate = lazy(() => import("../dashboard/Leopard/Create"));
const LeopardUpdate = lazy(() => import("../dashboard/Leopard/Update"));

//Private and Protected Routes
const PrivateRoute = lazy(() => import("./PrivateRoute"));
const AdminRoute = lazy(() => import("./AdminRoute"));
// const ModeratorRoute = lazy(() => import('./ModeratorRoute'));
// const TranslatorRole = lazy(() => import('./TranslatorRoute'));

const RestockSubscriptions = lazy(() =>
  import("../dashboard/Restock/RestockSubscriptions")
);
const RestockSubscribers = lazy(() =>
  import("../dashboard/Restock/RestockSubscribers")
);
const RestockSubscriber = lazy(() =>
  import("../dashboard/Restock/RestockSubscriber")
);
const RestockProducts = lazy(() =>
  import("../dashboard/Restock/RestockProducts")
);
const RestockProduct = lazy(() =>
  import("../dashboard/Restock/RestockProduct")
);

// Public Access
const TrackPage = lazy(() => import("../../pages/TrackPage"));
const Error404 = lazy(() => import("../Error404"));
const URLShortner = lazy(() => import("../dashboard/URLShotner"));
const TrackPageTCS = lazy(() => import("../../pages/TrackPageTCS"));
const TrackPageMnP = lazy(() => import("../../pages/TrackPageMnP"));
const Survey = lazy(() => import("../../pages/SurveyPage"));
const SurveyData = lazy(() => import("../../components/dashboard/SurveyData"));
const SurveyProductAnalytics = lazy(() =>
  import("../dashboard/SurveyProductAnalytics")
);

const SurveyAnalytics = lazy(() => import("../dashboard/SurveyAnalytics"));
const Records = lazy(() => import("../dashboard/Records"));

const Router = () => {
  return (
    <Suspense fallback={<div>Loading....</div>}>
      <Switch>
        <Route exact path='/login' component={Login} />
        <PrivateRoute exact path='/dashboard' component={Dashboard} />
        <PrivateRoute
          exact
          path='/dashboard/orders'
          component={Orders}
          role={"orders"}
        />
        <PrivateRoute
          exact
          path='/dashboard/analytics'
          component={OrdersAnalytics}
          role={"orders"}
        />
        <AdminRoute exact path='/dashboard/staff' component={Staff} />
        <AdminRoute exact path='/dashboard/staff/:id' component={UserById} />
        <AdminRoute exact path='/dashboard/stats/:id' component={UserStats} />
        <AdminRoute exact path='/dashboard/stats' component={StaffStats} />
        <AdminRoute exact path='/dashboard/stats/:id' component={UserStats} />
        <AdminRoute exact path='/dashboard/user/new' component={NewUser} />
        <PrivateRoute exact path='/dashboard/profile' component={UserProfile} />
        <PrivateRoute
          exact
          path='/dashboard/return-shipments'
          component={ReturnShipments}
          role={"upload-orders-return"}
        />
        <PrivateRoute
          exact
          path='/dashboard/return-shipments-records'
          component={ReturnShipmentsRecord}
          role={"upload-orders-return"}
        />
        <AdminRoute
          exact
          path='/dashboard/sms-settings'
          component={SettingsSMS}
        />
        <AdminRoute exact path='/dashboard/sms' component={SMSStatus} />
        <AdminRoute
          exact
          path='/dashboard/survey-product-analytics/:shopifyProductId'
          component={SurveyProductAnalytics}
        />
        <AdminRoute
          exact
          path='/dashboard/survey-analytics'
          component={SurveyAnalytics}
        />
        <AdminRoute exact path='/dashboard/records' component={Records} />
        <AdminRoute
          exact
          path='/dashboard/call-settings'
          component={CallSettings}
        />
        <AdminRoute exact path='/dashboard/shortner' component={URLShortner} />
        <AdminRoute
          exact
          path='/dashboard/survey-data'
          component={SurveyData}
        />
        <AdminRoute
          exact
          path='/dashboard/survey-settings'
          component={SurveySettings}
        />
        {/* templates */}
        <AdminRoute exact path='/dashboard/templates' component={Templates} />
        <AdminRoute exact path='/dashboard/temp/new' component={NewTemplates} />
        <AdminRoute exact path='/dashboard/temp/:id' component={TemplateById} />
        <AdminRoute
          exact
          path='/dashboard/tempRecords'
          component={TemplateRecords}
        />
        <PrivateRoute
          exact
          path='/dashboard/templates/message'
          component={SendTemplateMessage}
        />

        <PrivateRoute
          role={"translations"}
          exact
          path='/dashboard/translations'
          component={Translations}
        />
        <PrivateRoute
          role={"translations"}
          exact
          path='/dashboard/translations/:id'
          component={Translation}
        />
        <PrivateRoute
          role={"booked-orders"}
          exact
          path='/dashboard/booked-packets'
          component={BookedPackets}
        />
        <PrivateRoute
          exact
          path='/dashboard/order-products'
          component={OrderByProducts}
        />
        <PrivateRoute
          exact
          path='/dashboard/book-order'
          component={BookedOrder}
          role={"book-orders"}
        />
        <PrivateRoute
          exact
          path='/dashboard/merge-orders'
          component={MergeOrders}
          role={"book-orders"}
        />
        <PrivateRoute
          exact
          path='/dashboard/tcs/book-order'
          component={BookOrderTcs}
        />
        <PrivateRoute
          exact
          path='/dashboard/mnp/book-order'
          component={BookOrderMnP}
        />
        <PrivateRoute
          exact
          path='/dashboard/send-message'
          component={SendMessage}
        />
        <PrivateRoute
          exact
          path='/dashboard/sms-record'
          component={SMSRecord}
          role={"csr-mobile-message-record"}
        />
        <PrivateRoute exact path='/logout' component={Logout} />
        <PrivateRoute
          role={"track-orders"}
          exact
          path='/dashboard/orderbyid'
          component={TrackOrderByName}
        />
        <PrivateRoute
          role={"abandoned"}
          exact
          path='/dashboard/abandoned'
          component={AbandonedCard}
        />
        <PrivateRoute
          role={"ingredients"}
          exact
          path='/dashboard/ingredients'
          component={IngredientsRecord}
        />
        <PrivateRoute
          role={"short-urls"}
          exact
          path='/dashboard/short-url'
          component={ShortURLs}
        />
        <PrivateRoute
          role={"create-short-url"}
          exact
          path='/dashboard/short-url/create'
          component={CreateShortURLs}
        />
        <PrivateRoute
          role={"short-url-settings"}
          exact
          path='/dashboard/short-url/settings'
          component={ShortURLSettings}
        />
        <AdminRoute exact path='/dashboard/leopard' component={Leopard} />
        <AdminRoute
          exact
          path='/dashboard/leopard/create'
          component={LeopardCreate}
        />
        <AdminRoute
          exact
          path='/dashboard/leopard/update/:id'
          component={LeopardUpdate}
        />

        <AdminRoute
          exact
          path='/dashboard/restock'
          component={RestockSubscriptions}
        />
        <AdminRoute
          exact
          path='/dashboard/restock/products'
          component={RestockProducts}
        />
        <AdminRoute
          exact
          path='/dashboard/restock/products/:id'
          component={RestockProduct}
        />
        <AdminRoute
          exact
          path='/dashboard/restock/subscribers'
          component={RestockSubscribers}
        />
        <AdminRoute
          exact
          path='/dashboard/restock/subscribers/:id'
          component={RestockSubscriber}
        />

        <Route exact path='/track/:id' component={TrackPage} />
        <Route exact path='/trackt/:id' component={TrackPageTCS} />
        <Route exact path='/trackmnp/:id' component={TrackPageMnP} />
        <Route exact path='/r/:shortcode' component={RedirectPage} />
        <Route exact path='/verify/:token' component={VerifyOrder} />
        <Route exact path='/survey' component={Survey} />
        <Route
          exact
          path='/hr-assessment'
          component={() => {
            window.location.href = "https://nutrifactor.com.pk";
            return null;
          }}
        />
        <Route
          exact
          path='/test'
          component={() => {
            window.location.href = "https://nutrifactor.com.pk";
            return null;
          }}
        />
        <Route
          exact
          path='/nutritionist-assessment'
          component={() => {
            window.location.href = "https://nutrifactor.com.pk";
            return null;
          }}
        />
        <Route component={Error404} />
      </Switch>
    </Suspense>
  );
};

export default Router;
