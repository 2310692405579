import { REDIRECT_URL, REDIRECT_URL_SUCCESS, REDIRECT_URL_FAILED, VERIFY_ORDER_TOKEN, VERIFY_ORDER_TOKEN_SUCCESS, VERIFY_ORDER_TOKEN_FAILED } from "../actions/types";

const initialState = {
    loading: true,
    payload: null,
    verifiedTokenData: null,
    error: null
}


// eslint-disable-next-line import/no-anonymous-default-export
export default function(state= initialState, action) {
const {type, payload} = action
switch (type) {
    case REDIRECT_URL:     
        return{
            ...state,
            loading: true,
        };
    case REDIRECT_URL_SUCCESS:     
        return{
            ...state,
            payload: payload,
            loading: false,
        };
    case REDIRECT_URL_FAILED:  
        return{
            ...state,
            loading: false,
            error: payload
        };

    case VERIFY_ORDER_TOKEN:
        return {
            ...state,
            loading: true,
        }
    case VERIFY_ORDER_TOKEN_SUCCESS:
        return {
            ...state,
            loading: false,
            verifiedTokenData: payload
        }
    case VERIFY_ORDER_TOKEN_FAILED:
        return {
            ...state,
            loading: false,
            error: payload
        }
    default:
        return state;
}
}