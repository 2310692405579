import { SMS_SETTINGS, SMS_SETTINGS_ERROR } from '../actions/types'

const initialState = {
    settings: null,
    loading: true,
    error: null,
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case SMS_SETTINGS:
            return {
                ...state,
                settings: payload,
                loading: false,
            }
        case SMS_SETTINGS_ERROR:
            return {
                ...state,
                loading: false,
                error: payload,
            }
        default:
            return state;
    }
}