import { RETURN_SHIPMENTS, RETURN_SHIPMENTS_ERROR } from '../actions/types';

const initialState = {
  data: {
    data: [],
    totalRecords: 0,
  },
  error: null,
  loading: true,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case RETURN_SHIPMENTS:
      return {
        ...state,
        data: payload,
        error: null,
        loading: false,
      };
    case RETURN_SHIPMENTS_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    default:
      return state;
  }
}
