import React, { useState } from 'react';
import { Layout, Heading, Loading, Frame } from '@shopify/polaris';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

function HomePage({ isAuthenticated, loading }) {
  const [seconds, setSeconds] = useState(30);
  setTimeout(() => {
    if (seconds > 0) {
      setSeconds(seconds - 1);
    }
  }, 1000);

  if (loading) {
    return (
      <Frame>
        <Loading />
      </Frame>
    );
  }
  if (isAuthenticated) {
    return <Redirect to='/dashboard' />;
  }

  if (seconds <= 0) {
    window.location = 'https://www.nutrifactor.com.pk/';
  }
  return (
    <Layout.Section>
      <div
        style={{
          height: '100vh',
          display: 'flex',
          width: '100%',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <img
          style={{ width: '40%' }}
          src='https://cdn.shopify.com/s/files/1/0310/5472/5260/files/nutrifactor_logo_200px_height_b18c345a-fdd4-44b7-bd04-2f9cb2d697da.png?v=1597833207'
          alt='Logo Nutrifactor'
        />
        <Heading element='h1'>Oh friend! you lost your way!</Heading>
        <p>We will be redirect you to main site in {seconds} second</p>
      </div>
    </Layout.Section>
  );
}

HomePage.prototype = {
  isAuthenticated: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  loading: state.auth.loading,
});

export default connect(mapStateToProps)(HomePage);
