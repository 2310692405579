const {
  SHORT_URLS_SUCCESS,
  SHORT_URLS_ERROR,
  SEARCH_URL,
} = require('../actions/types');

const initialState = {
  data: null,
  filteredData: [],
  loading: true,
  error: null,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case SHORT_URLS_SUCCESS:
      return {
        ...state,
        data: payload,
        filteredData: payload.data.slice(0, 10),
        loading: false,
      };
    case SEARCH_URL:
      return {
        ...state,
        filteredData: payload,
        loading: false,
      };

    case SHORT_URLS_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    default:
      return state;
  }
}
