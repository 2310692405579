const {
  GET_SMS_RECORD_BY_PHONE,
  GET_SMS_RECORD_BY_PHONE_REMOVE,
} = require('../actions/types');

const initialState = {
  data: null,
  loading: true,
  error: null,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_SMS_RECORD_BY_PHONE:
      return {
        ...state,
        data: payload,
        loading: false,
        error: null,
      };
    case GET_SMS_RECORD_BY_PHONE_REMOVE:
      return {
        ...state,
        data: null,
        loading: false,
        error: null,
      };
    default:
      return state;
  }
}
