const { CHECKOUT_DATA, CHECKOUT_DATA_ERROR } = require('../actions/types');

const initialState = {
  data: [],
  loading: true,
  error: null,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case CHECKOUT_DATA:
      return {
        ...state,
        data: payload,
        loading: false,
      };

    case CHECKOUT_DATA_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    default:
      return state;
  }
}
