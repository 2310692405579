const {
  GET_RECORDS,
  GET_RECORDS_ERROR,
  GET_TEMPLATES,
  GET_TEMPLATES_ERROR,
  UPDATE_TEMPLATES,
  UPDATE_TEMPLATES_ERROR,
} = require("../actions/types");

const initialState = {
  data: null,
  records: null,
  loading: true,
  error: null,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_RECORDS:
      return {
        ...state,
        records: payload,
        loading: false,
        error: null,
      };
    case GET_TEMPLATES:
    case UPDATE_TEMPLATES:
      return {
        ...state,
        data: payload,
        loading: false,
        error: null,
      };
    case GET_TEMPLATES_ERROR:
    case GET_RECORDS_ERROR:
    case UPDATE_TEMPLATES_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    default:
      return state;
  }
}
