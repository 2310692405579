import { SHORT_LINK, SHORT_LINK_ERROR } from '../actions/types'

const initialState = {
    linkId: null,
    loading: true,
    error: null,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case SHORT_LINK:
            return {
                ...state,
                linkId: payload
            }
        case SHORT_LINK_ERROR:
            return {
                ...state,
                error: payload,
            }
        default:
            return state
    }
}