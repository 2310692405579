const { LEOPARD_CITIES, LEOPARD_CITIES_ERROR } = require('../actions/types');

const initialState = {
  loading: true,
  data: null,
  error: null,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case LEOPARD_CITIES:
      return {
        ...state,
        loading: false,
        data: payload,
        error: null,
      };
    case LEOPARD_CITIES_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    default:
      return state;
  }
}
