import { combineReducers } from 'redux';
import alert from './alert';
import auth from './auth';
import orders from './orders';
import ordersAnalytics from './ordersAnalytics';
import order from './order';
import orderFulfillment from './bookOrderAPI';
import staff from './staff';
import orderConfirm from './orderConfirmation';
import smsSettings from './smsSettings';
import callSettings from './callSettings';
import URLShortner from './URLShortner';
import surveySettings from './survey';
import surveyData from './surveyData';
import Records from './records';
import Templates from './templates';
import UserStats from './userStats';
import AllStaffStats from './allStaffStats';
import translations from './translations';
import translation from './translationById';
import QRCode from './qrcode';
import ReturnShipmentsData from './returnShipmentsData';
import checkout from './checkout';
import ingredients from './ingredients';
import smsRecord from './smsRecord';
import smsRecordByPhone from './smsRecordByPhone';
import shortURLs from './shorturls';
import shortURLSettings from './shorturl.settings';
import leopardAccounts from './leopardAccounts';
import leopardAccount from './leopardAccount';
import leopardCities from './leopardCities';
import restockSubscriptions from './restockSubscriptions';
import restockSubscribers from './restockSubscribers';
import restockSubscriber from './restockSubscriber';
import restockProducts from './restockProducts';
import restockVariant from './restockVariant';
import mergeOrder from './merge.order';

export default combineReducers({
  alert,
  auth,
  orders,
  ordersAnalytics,
  order,
  orderFulfillment,
  staff,
  orderConfirm,
  smsSettings,
  callSettings,
  URLShortner,
  surveySettings,
  surveyData,
  Records,
  Templates,
  UserStats,
  AllStaffStats,
  translations,
  translation,
  QRCode,
  ReturnShipmentsData,
  checkout,
  ingredients,
  smsRecord,
  smsRecordByPhone,
  shortURLs,
  shortURLSettings,
  leopardAccounts,
  leopardAccount,
  leopardCities,
  restockSubscriptions,
  restockSubscribers,
  restockSubscriber,
  restockProducts,
  restockVariant,
  mergeOrder,
});
