const {
  GET_ORDERS,
  GET_ORDERS_ERROR,
  GET_ORDER,
  GET_ORDER_ERROR,
  TRACK_ORDER_DATA,
  TRACK_ORDER_DATA_ERROR,
  ORDERS_BY_PRODUCTS,
  ORDERS_BY_PRODUCTS_ERROR,
} = require('../actions/types');

const initialState = {
  ordersData: null,
  trackingData: null,
  orderByProductsData: null,
  loading: true,
  error: null,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_ORDERS:
    case GET_ORDER:
      return {
        ...state,
        ordersData: payload,
        loading: false,
      };
    case TRACK_ORDER_DATA:
      return {
        ...state,
        trackingData: payload,
        loading: false,
      };
    case ORDERS_BY_PRODUCTS:
      return{
        ...state,
        orderByProductsData: payload,
        loading: false
      }
    case GET_ORDERS_ERROR:
    case GET_ORDER_ERROR:
    case TRACK_ORDER_DATA_ERROR:
    case ORDERS_BY_PRODUCTS_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    default:
      return state;
  }
}
