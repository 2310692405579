import axios from 'axios';

import { setAuthToken } from './setAuthToken';

// const tokenExpired = (token) => {
//   const expiry = JSON.parse(atob(token.split('.')[1])).exp;

//   return Math.floor(new Date().getTime() / 1000) >= expiry;
// };

const shouldIntercept = (error) => {
  try {
    return error.response.status === 401;
  } catch (e) {
    return false;
  }
};

const handleTokenRefresh = () => {
  let authTokens = localStorage.getItem('token');

  let refreshToken = null;

  if (authTokens) {
    authTokens = JSON.parse(authTokens);
    refreshToken = authTokens.refreshToken;
  }

  return new Promise((resolve, reject) => {
    axios
      .post('/api/refresh/token', {
        refreshToken,
      })
      .then(({ data }) => {
        const tokenData = {
          ...data,
        };
        resolve(tokenData);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const useAxios = async () => {
  let isRefreshing = false;
  let failedQueue = [];

  const axiosInstance = axios.create({});

  const processQueue = (error, token = null) => {
    failedQueue.forEach((prom) => {
      if (error) {
        prom.reject(error);
      } else {
        prom.resolve(token);
      }
    });

    failedQueue = [];
  };

  axiosInstance.interceptors.request.use(async (req) => {
    let authTokens = localStorage.getItem('token');

    if (authTokens) {
      authTokens = JSON.parse(authTokens);
      req.headers.Authorization = 'Bearer ' + authTokens.accessToken;
      return req;
    } else {
      delete req.headers.Authorization;
      return req;
    }
  });

  axiosInstance.interceptors.response.use(
    (res) => {
      return res;
    },
    async (res) => {
      if (!shouldIntercept(res)) {
        return Promise.reject(res);
      }

      if (res.config._retry || res.config._queued) {
        return Promise.reject(res);
      }

      const originalRequest = res.config;

      if (isRefreshing) {
        try {
          const token = await new Promise(function (resolve, reject) {
            failedQueue.push({ resolve, reject });
          });
          originalRequest._queued = true;
          originalRequest.headers['Authorization'] = 'Bearer ' + token;
          return axiosInstance.request(originalRequest);
        } catch (error) {
          return await Promise.reject(res);
        }
      }

      originalRequest._retry = true;
      isRefreshing = true;

      return new Promise((resolve, reject) => {
        handleTokenRefresh
          .call(handleTokenRefresh)
          .then((newRefreshToken) => {
            axiosInstance.defaults.headers.common['Authorization'] =
              'Bearer ' + newRefreshToken.accessToken;
            originalRequest.headers['Authorization'] =
              'Bearer ' + newRefreshToken.accessToken;

            setAuthToken({
              ...newRefreshToken,
            });

            processQueue(null, newRefreshToken.accessToken);
            resolve(axiosInstance.request(originalRequest));
          })
          .catch((err) => {
            processQueue(err, null);
            reject(err);
          })
          .finally(() => {
            isRefreshing = false;
          });
      });
    }
  );

  return axiosInstance;
};

export default useAxios;
