const {
  GET_ALL_USER_STATS,
  GET_ALL_USER_STATS_ERROR,
} = require('../actions/types');

const initialState = {
  data: null,
  loading: true,
  error: null,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_ALL_USER_STATS:
      return {
        ...state,
        data: payload,
        loading: false,
        error: null,
      };
    case GET_ALL_USER_STATS_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    default:
      return state;
  }
}
