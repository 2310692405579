export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGOUT = 'LOGOUT';
export const CLEAR_PROFILE = 'CLEAR_PROFILE';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';
export const GET_ORDERS = 'GET_ORDERS';
export const GET_ORDERS_ERROR = 'GET_ORDERS_ERROR';
export const GET_ORDERS_ANALYTICS = 'GET_ORDERS_ANALYTICS';
export const GET_ORDERS_ANALYTICS_ERROR = 'GET_ORDERS_ANALYTICS_ERROR';
export const GET_ORDER_BY_ID_REQUEST = 'GET_ORDER_BY_ID_REQUEST';
export const GET_ORDER_BY_ID = 'GET_ORDER_BY_ID';
export const GET_ORDER_BY_ID_ERROR = 'GET_ORDER_BY_ID_ERROR';
export const GET_ORDER = 'GET_ORDERS';
export const GET_ORDER_ERROR = 'GET_ORDERS_ERROR';
export const BOOK_ORDER_API = 'BOOK_ORDER_API';
export const BOOK_ORDER_API_ERROR = 'BOOK_ORDER_API_ERROR';
export const BOOKED_PACKETS = 'BOOKED_PACKETS';
export const BOOKED_PACKETS_ERROR = 'BOOKED_PACKETS_ERROR';
export const GET_STAFF = 'GET_STAFF';
export const GET_STAFF_ERROR = 'GET_STAFF_ERROR';
export const STAFF_UPDATE = 'STAFF_UPDATE';
export const STAFF_UPDATE_ERROR = 'STAFF_UPDATE_ERROR';
export const PROFILE_UPDATE = 'PROFILE_UPDATE';
export const PROFILE_UPDATE_ERROR = 'PROFILE_UPDATE_ERROR';
export const TRACK_ORDER_DATA = 'TRACK_ORDER_DATA';
export const TRACK_ORDER_DATA_ERROR = 'TRACK_ORDER_DATA_ERROR';
export const ORDERS_BY_PRODUCTS = 'ORDERS_BY_PRODUCTS';
export const ORDERS_BY_PRODUCTS_ERROR = 'ORDERS_BY_PRODUCTS_ERROR';

export const REDIRECT_URL = 'REDIRECT_URL';
export const REDIRECT_URL_SUCCESS = 'REDIRECT_URL_SUCCESS';
export const REDIRECT_URL_FAILED = 'REDIRECT_URL_FAILED';

export const VERIFY_ORDER_TOKEN = 'VERIFY_ORDER_TOKEN';
export const VERIFY_ORDER_TOKEN_SUCCESS = 'VERIFY_ORDER_TOKEN_SUCCESS';
export const VERIFY_ORDER_TOKEN_FAILED = 'VERIFY_ORDER_TOKEN_FAILED';

export const SMS_SETTINGS = 'SMS_SETTINGS';
export const SMS_SETTINGS_ERROR = 'SMS_SETTINGS_ERROR';

export const SURVEY_SETTINGS = 'SURVEY_SETTINGS';
export const SURVEY_SETTINGS_ERROR = 'SURVEY_SETTINGS_ERROR';
// Templates
export const GET_MESSAGES = 'GET_MESSAGES';
export const GET_MESSAGES_ERROR = 'GET_MESSAGES_ERROR';
export const SEND_MESSAGE = 'SEND_MESSAGE';
export const SEND_MESSAGE_ERROR = 'SEND_MESSAGE_ERROR';
export const GET_TEMPLATES = 'GET_TEMPLATES';
export const GET_TEMPLATES_ERROR = 'GET_TEMPLATES_ERROR ';
export const UPDATE_TEMPLATES = 'UPDATE_TEMPLATES';
export const UPDATE_TEMPLATES_ERROR = 'UPDATE_TEMPLATES_ERROR';

export const GET_RECORDS = 'GET_RECORDS';
export const GET_RECORDS_ERROR = 'GET_RECORDS_ERROR';

export const SURVEY_DATA = 'SURVEY_DATA';
export const SURVEY_DATA_ERROR = 'SURVEY_DATA_ERROR';

export const CALL_SETTINGS = 'CALL_SETTINGS';
export const CALL_SETTINGS_ERROR = 'CALL_SETTINGS_ERROR';

export const SHORT_LINK = 'SHORT_LINK';
export const SHORT_LINK_ERROR = 'SHORT_LINK_ERROR';

export const GET_USER_STATS = 'GET_USER_STATS';
export const GET_USER_STATS_ERROR = 'GET_USER_STATS_ERROR';

export const GET_ALL_USER_STATS = 'GET_ALL_USER_STATS';
export const GET_ALL_USER_STATS_ERROR = 'GET_ALL_USER_STATS_ERROR';

// Translations

export const GET_TRANSLATIONS = 'GET_TRANSLATIONS';
export const GET_TRANSLATIONS_ERRORS = 'GET_TRANSLATIONS_ERRORS';

export const GET_TRANSLATION = 'GET_TRANSLATION';
export const GET_TRANSLATIONS_ERROR = 'GET_TRANSLATIONS_ERROR';

// QR Code

export const GET_QRCODE = 'GET_QRCODE';
export const GET_QRCODE_ERROR = 'GET_QRCODE_ERROR';

// Return Shipments Data

export const RETURN_SHIPMENTS = 'RETURN_SHIPMENTS';
export const RETURN_SHIPMENTS_ERROR = 'RETURN_SHIPMENTS_ERROR';

//OTP
export const OPT_CHECK = 'OPT_CHECK';

// Checkout

export const CHECKOUT_DATA = 'CHECKOUT_DATA';
export const CHECKOUT_DATA_ERROR = 'CHECKOUT_DATA_ERROR';

// Ingredients

export const ADD_INGREDIENT = 'ADD_INGREDIENT';
export const ADD_INGREDIENT_ERROR = 'ADD_INGREDIENT_ERROR';
export const GET_INGREDIENTS = 'GET_INGREDIENTS';
export const GET_INGREDIENTS_ERROR = 'GET_INGREDIENTS_ERROR';

// SMS Record

export const GET_SMS_RECORD = 'GET_SMS_RECORD';
export const GET_SMS_RECORD_ERROR = 'GET_SMS_RECORD_ERROR';

// SMS Record By Phone

export const GET_SMS_RECORD_BY_PHONE = 'GET_SMS_RECORD_BY_PHONE';
export const GET_SMS_RECORD_BY_PHONE_REMOVE = 'GET_SMS_RECORD_BY_PHONE_REMOVE';

// Short URLs

export const SHORT_URLS_SUCCESS = 'SHORT_URLS_SUCCESS';
export const SHORT_URLS_ERROR = 'SHORT_URLS_ERROR';
export const SEARCH_URL = 'SEARCH_URL';

export const SHORT_URLS_SETTINGS_SUCCESS = 'SHORT_URLS_SETTINGS_SUCCESS';
export const SHORT_URLS_SETTINGS_ERROR = 'SHORT_URLS_SETTINGS_ERROR';

// Leopard Accounts
export const LEOPARD_ACCOUNTS = 'LEOPARD_ACCOUNTS';
export const LEOPARD_ACCOUNTS_ERROR = 'LEOPARD_ACCOUNTS_ERROR';
export const LEOPARD_ACCOUNT = 'LEOPARD_ACCOUNT';
export const LEOPARD_ACCOUNT_ERROR = 'LEOPARD_ACCOUNT_ERROR';

// Leopard Cities
export const LEOPARD_CITIES = 'LEOPARD_CITIES';
export const LEOPARD_CITIES_ERROR = 'LEOPARD_CITIES_ERROR';

// Restock Subscriptions
export const GET_RESTOCK_SUBSCRIPTIONS_REQUEST =
  'GET_RESTOCK_SUBSCRIPTIONS_REQUEST';
export const GET_RESTOCK_SUBSCRIPTIONS_SUCCESS =
  'GET_RESTOCK_SUBSCRIPTIONS_SUCCESS';
export const RESTOCK_SUBSCRIPTIONS_ERROR = 'RESTOCK_SUBSCRIPTIONS_ERROR';

// Restock Subscribers
export const GET_RESTOCK_SUBSCRIBERS_REQUEST =
  'GET_RESTOCK_SUBSCRIBERS_REQUEST';
export const GET_RESTOCK_SUBSCRIBERS_SUCCESS =
  'GET_RESTOCK_SUBSCRIBERS_SUCCESS';
export const RESTOCK_SUBSCRIBERS_ERROR = 'RESTOCK_SUBSCRIBERS_ERROR';

// Restock Subscriber
export const GET_RESTOCK_SUBSCRIBER_REQUEST = 'GET_RESTOCK_SUBSCRIBER_REQUEST';
export const GET_RESTOCK_SUBSCRIBER_SUCCESS = 'GET_RESTOCK_SUBSCRIBER_SUCCESS';
export const RESTOCK_SUBSCRIBER_ERROR = 'RESTOCK_SUBSCRIBER_ERROR';

// Restock Products
export const GET_RESTOCK_PRODUCTS_REQUEST = 'GET_RESTOCK_PRODUCTS_REQUEST';
export const GET_RESTOCK_PRODUCTS_SUCCESS = 'GET_RESTOCK_PRODUCTS_SUCCESS';
export const RESTOCK_PRODUCTS_ERROR = 'RESTOCK_PRODUCTS_ERROR';

// Restock Variant
export const GET_RESTOCK_VARIANT_REQUEST = 'GET_RESTOCK_VARIANT_REQUEST';
export const GET_RESTOCK_VARIANT_SUCCESS = 'GET_RESTOCK_VARIANT_SUCCESS';
export const RESTOCK_VARIANT_ERROR = 'RESTOCK_VARIANT_ERROR';

// Merge Order -  Get Order
export const GET_ORDER_DATA_REQUEST = 'GET_ORDER_DATA_REQUEST';
export const GET_ORDER_DATA = 'GET_ORDER_DATA';
export const GET_ORDER_DATA_ERROR = 'GET_ORDER_DATA_ERROR';
